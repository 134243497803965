@tailwind base;
@tailwind components;
@tailwind utilities;
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.is-scrollbar-hidden {
  @apply [scrollbar-width:1px]; /* Firefox */
}

.is-scrollbar-hidden::-webkit-scrollbar {
  @apply hidden;
}
.scrollable-modal-body {
  max-height: 60vh; /* Adjust this value as needed */
  overflow-y: auto;
}
